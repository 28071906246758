import { ReactNode } from 'react';

import Text from '@/components/data-display/Text';

const DashboardBodyLayout = ({
  actions,
  children,
  extra,
  title,
  showExtraOnMobile = false,
}: {
  actions?: ReactNode;
  children: ReactNode;
  extra?: ReactNode;
  title?: ReactNode;
  showExtraOnMobile?: boolean;
}) => {
  const extraWrapperClassNames = !showExtraOnMobile
    ? 'hidden h-full relative flex-1 basis-[465px] md:flex flex-col overflow-auto gap-4 px-2'
    : 'md:h-full relative flex-1 basis-auto md:basis-[465px] flex flex-col overflow-auto gap-4 px-2';

  return (
    <div className="h-full w-full relative bg-background flex flex-col md:flex-row md:flex-wrap p-3 gap-y-4 overflow-auto">
      <div className="max-w-full max-h-full md:h-full relative flex flex-col flex-1 md:basis-[595px] md:overflow-auto px-2 gap-4">
        <div className="flex justify-between items-center">
          {typeof title === 'string' ? (
            <Text block variant="h2" className="font-serif pt-1">
              {title}
            </Text>
          ) : (
            title
          )}

          {actions}
        </div>

        <div className="flex-1 relative flex flex-col overflow-auto gap-4">
          {children}
        </div>
      </div>

      {extra && <div className={extraWrapperClassNames}>{extra}</div>}
    </div>
  );
};

export default DashboardBodyLayout;
