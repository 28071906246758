import { HTMLProps, ReactNode } from 'react';

import SVG, { PredefinedIconsTypes } from '@/components/data-display/SVG';
import Text from '@/components/data-display/Text';

import { wrapWithErrorBoundary } from '@/components/feedback/utils/wrapWithErrorBoundary';

import { classNames } from '@/utils/classNames';

interface FancyCardProps extends Omit<HTMLProps<HTMLDivElement>, 'title'> {
  contentClassName?: string;
  icon?: PredefinedIconsTypes | JSX.Element;
  extra?: ReactNode;
  title?: ReactNode;
}

const FancyCard = ({
  children,
  className,
  contentClassName,
  extra,
  icon,
  title,
  // cardTitle,
  ...rest
}: FancyCardProps) => {
  return (
    <div
      className={classNames(
        'border rounded-lg relative flex flex-col overflow-hidden',
        className
      )}
      {...rest}
    >
      {/* Toolbar */}
      <div
        className={classNames(
          'py-2 px-3',
          // Refactor to position.relative + remove zIndice
          'flex bg-surface-secondary items-center border-b gap-2 w-full justify-between z-1'
        )}
      >
        <div className="flex items-center space-x-3 overflow-x-auto overflow-y-hidden scrollbar-hide">
          {icon && <SVG icon={icon} />}

          {typeof title === 'string' ? (
            <Text className="font-bold font-serif -mb-1">{title}</Text>
          ) : (
            title
          )}
        </div>

        {extra && <div>{extra}</div>}
      </div>

      <div
        className={classNames(
          'flex flex-col gap-4 overflow-auto h-full',
          contentClassName
        )}
      >
        {children}
      </div>

      {/* Here we could wrap this in a CardBody - potentially improved a11y */}
      {/* <CustomScrollbarBox
        className={classNames('bg-white px-4 flex-1 relative overflow-auto')}
      >
        <div className="h-full">{children}</div>
      </CustomScrollbarBox> */}
    </div>
  );
};

export default FancyCard;

export const FancyCardWithBoundary = wrapWithErrorBoundary(FancyCard);
